<template>
  <div class="card">
    <div class="card-body">
        <div class="row">
            <div class="col-md-3">
                <div class="fv-row mb-10">
                    <!--begin::Label-->
                    <label class="fs-6 fw-bold mb-2">
                        İndirim Tipi
                        <i class="fas fa-exclamation-circle  fs-7" data-bs-toggle="tooltip" title="" data-bs-original-title="Select a discount type that will be applied to this product" aria-label="Select a discount type that will be applied to this product"></i></label>
                    <!--End::Label-->
                    <div class="d-flex flex-column">
                        <el-radio v-if="paket"  v-model="paket.discountType" class="m-0 w-100" label="NO_DISCOUNT" checked="true" size="large" border>İndirimsiz</el-radio>
                        <el-radio v-if="paket"  v-model="paket.discountType" class="mx-0 my-5 w-100" label="PERCENTAGE" size="large" border>Yüzdelik İndirim</el-radio>
                        <el-radio v-if="paket"  v-model="paket.discountType" class="m-0 w-100" label="FIXED_PRICE" size="large" border>Sabit İndirim</el-radio>
                    </div>
                </div>
            </div>
            <div class="col-md-9">
                <div class="row">
                    <div class="col-md-8">
                        <!--begin::Input group-->
                        <div class="mb-10 fv-row fv-plugins-icon-container">
                            <!--begin::Label-->
                            <label class="required form-label">Ürün Fiyatı</label>
                            <!--end::Label-->
                            <!--begin::Input-->
                            <input v-if="paket" type="number" class="form-control form-control-solid" name="unitPrice" v-model="paket.unitPrice"  placeholder="Ürün Fiyatı"/>
                            <!--end::Input-->

                        </div>
                        <!--end::Input group-->
                    </div>

                    <div class="col-md-4">
                        <div class="col-md-8">
                        <!--begin::Label-->
                        <label class="required form-label">Vergi Oranı</label>
                        <!--end::Label-->
                        <!--begin::Input-->
                        <div>
                        <el-select v-if="paket" v-model="paket.taxPercent">
                            <el-option
                            
                            v-for="(item,index) in taxes"
                            :key="index"
                            :label="item.label"
                            :value="item.value"
                            />
                            </el-select>
                        </div>
                        <!--end::Input-->
                    </div>
                </div>
                
                    
                </div>
                    <div class="row">
                        <div class="col-md-8"> 
                            <div v-if="paket && paket.discountType == 'PERCANTAGE'" class="fv-row mb-100">
                                <div class="d-flex align-items-start justify-content-center">
                                    <span class="fw-bolder fs-3" id="kt_ecommerce_add_product_discount_label">{{ paket.discountPercent }}</span>
                                    <span class="fw-bolder fs-4 mt-1 ms-2">%</span>
                                </div>
                                <!--begin::Label-->
                                <label class="required form-label">İndirim Oranı</label>
                                <!--end::Label-->
                                <!--begin::Input-->
                                <el-slider v-if="paket" v-model="paket.discountPercent" />
                                <!--end::Input-->
                            </div>
                        </div>
                        
                        <div v-if="paket && paket.discountType == 'FIXED_PRICE'" class="fv-row mb-100 col-md-8">
                            <!--begin::Label-->
                            <label class="required form-label">İndirim Tutarı</label>
                            <!--end::Label-->
                            <!--begin::Input-->
                            <input v-if="paket" type="text" v-model="paket.discountPrice"  name="price" class="form-control mb-2" placeholder="Product price" />
                            <!--end::Input-->
                        </div>
                        
                        <div class="col-md-4">
                            <label class="required form-label">Toplam Fiyat</label>
                            <div>
                                <span class="fw-bolder fs-2x" id="kt_ecommerce_add_product_discount_label">{{ calculateFinalPrice }}</span>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
        
        <!--begin::Input group-->
    
    </div>
  </div>
  

</template>


<script>
import {ElRadio, ElSelect, ElSlider,ElInput} from 'element-plus';
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import store from '@/store';
import { ref, computed, onMounted, watch } from 'vue';
export default {
    
    setup() {
        const paket  = ref([]);
        const taxes = ref([
            {
                label:"0%",
                value:0,
            },
            {
                label:"1%",
                value:1,
            },
            {
                label:"8%",
                value:8,
            },
            {
                label:"18%",
                value:18,
            },
        ]);

        const calculateFinalPrice = computed(() => {
             //eğer kdv değeri 0 ise çarpma işlemini yapma değerini değiştirmeden tut
             let finalPrice = paket.value.taxPercent != 0 ? parseFloat(paket.value.unitPrice) + ((paket.value.unitPrice * paket.value.taxPercent) / 100) :  parseFloat(paket.value.unitPrice) ;
    
            if(paket.value.discountType == 'PERCANTAGE'){
                finalPrice = finalPrice - ((finalPrice * paket.value.discountPercent) / 100 );
            }else if(paket.value.discountType == 'FIXED_PRICE'){
                finalPrice =  finalPrice - parseFloat(paket.value.discountPrice); 
            }  
            
            return finalPrice.toFixed(2);
        });
        
        
        onMounted(() => {
            paket.value = store.getters.packageItem;
        });

        watch(calculateFinalPrice, (currentValue, oldValue) => {
            paket.value.totalPrice = currentValue;
        });

        return {
            taxes,
            paket,
            calculateFinalPrice
        }        
    }

}
</script>